import $ from 'jquery';
import 'what-input';
import './lib/foundation-explicit-pieces';

window.jQuery = $;

// initialize foundation
window.jQuery(document).ready(function () {
    window.jQuery(document).foundation();
    window.jQuery('#top-bar-menu li a').click(function () {
        if (window.jQuery('.title-bar').is(':visible')) {
            window.jQuery('[data-responsive-toggle] .menu-icon').trigger('click');
        }
    });
});
